.procution-line-title {
    letter-spacing: 1.08px;
    color: #222D39;
    opacity: 1;
    font-size: 36px;
    font-weight: 500;
}

.production-line-scanner {
    display: block;
    width: max-content;
    background-color: #F6F6F6 !important;
    margin-left: auto;
    margin-right: auto;
    height: max-content;
    background: #F1F1FF 0% 0% no-repeat padding-box;
}

.loader {
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    text-indent: -9999em;
    animation: mulShdSpin 1.8s infinite ease;
    transform: translateZ(0);
}

@keyframes mulShdSpin {

    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #6b7c99,
            1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2),
            2.5em 0em 0 0em rgba(107, 124, 153, 0.2),
            1.75em 1.75em 0 0em rgba(107, 124, 153, 0.2),
            0em 2.5em 0 0em rgba(107, 124, 153, 0.2),
            -1.8em 1.8em 0 0em rgba(107, 124, 153, 0.2),
            -2.6em 0em 0 0em rgba(107, 124, 153, 0.5),
            -1.8em -1.8em 0 0em rgba(107, 124, 153, 0.7);
    }

    12.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(107, 124, 153, 0.7),
            1.8em -1.8em 0 0em #6b7c99,
            2.5em 0em 0 0em rgba(107, 124, 153, 0.2),
            1.75em 1.75em 0 0em rgba(107, 124, 153, 0.2),
            0em 2.5em 0 0em rgba(107, 124, 153, 0.2),
            -1.8em 1.8em 0 0em rgba(107, 124, 153, 0.2),
            -2.6em 0em 0 0em rgba(107, 124, 153, 0.2),
            -1.8em -1.8em 0 0em rgba(107, 124, 153, 0.5);
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em rgba(107, 124, 153, 0.5),
            1.8em -1.8em 0 0em rgba(107, 124, 153, 0.7),
            2.5em 0em 0 0em #6b7c99,
            1.75em 1.75em 0 0em rgba(107, 124, 153, 0.2),
            0em 2.5em 0 0em rgba(107, 124, 153, 0.2),
            -1.8em 1.8em 0 0em rgba(107, 124, 153, 0.2),
            -2.6em 0em 0 0em rgba(107, 124, 153, 0.2),
            -1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2);
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(107, 124, 153, 0.2),
            1.8em -1.8em 0 0em rgba(107, 124, 153, 0.5),
            2.5em 0em 0 0em rgba(107, 124, 153, 0.7),
            1.75em 1.75em 0 0em #6b7c99,
            0em 2.5em 0 0em rgba(107, 124, 153, 0.2),
            -1.8em 1.8em 0 0em rgba(107, 124, 153, 0.2),
            -2.6em 0em 0 0em rgba(107, 124, 153, 0.2),
            -1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2);
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em rgba(107, 124, 153, 0.2),
            1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2),
            2.5em 0em 0 0em rgba(107, 124, 153, 0.5),
            1.75em 1.75em 0 0em rgba(107, 124, 153, 0.7),
            0em 2.5em 0 0em #6b7c99,
            -1.8em 1.8em 0 0em rgba(107, 124, 153, 0.2),
            -2.6em 0em 0 0em rgba(107, 124, 153, 0.2),
            -1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2);
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(107, 124, 153, 0.2),
            1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2),
            2.5em 0em 0 0em rgba(107, 124, 153, 0.5),
            1.75em 1.75em 0 0em rgba(107, 124, 153, 0.5),
            0em 2.5em 0 0em rgba(107, 124, 153, 0.7),
            -1.8em 1.8em 0 0em #6b7c99,
            -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em rgba(107, 124, 153, 0.2),
            1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2),
            2.5em 0em 0 0em rgba(107, 124, 153, 0.2),
            1.75em 1.75em 0 0em rgba(107, 124, 153, 0.2),
            0em 2.5em 0 0em rgba(107, 124, 153, 0.5),
            -1.8em 1.8em 0 0em rgba(107, 124, 153, 0.7),
            -2.6em 0em 0 0em #6b7c99,
            -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em rgba(107, 124, 153, 0.2),
            1.8em -1.8em 0 0em rgba(107, 124, 153, 0.2),
            2.5em 0em 0 0em rgba(107, 124, 153, 0.2),
            1.75em 1.75em 0 0em rgba(107, 124, 153, 0.2),
            0em 2.5em 0 0em rgba(107, 124, 153, 0.2),
            -1.8em 1.8em 0 0em rgba(107, 124, 153, 0.5),
            -2.6em 0em 0 0em rgba(107, 124, 153, 0.7),
            -1.8em -1.8em 0 0em #6b7c99;
    }
}



/* Stripping css */
/* Grid Layout */
.production-line-stripping {
    padding-top: 10px;
    background-color: #FFFFFF;
}

/* Batch Column */
.batch-column {
    padding: 10px;
    /* height: 600px; */
}

.batch-column h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}

.batch-item {
    align-items: center;
    height: 500px;
    overflow-y: auto;
}

.batch-items {
    display: flex;
    padding: 10px 0;
    background-color: #F1F1FF;
    border-bottom: 1px solid #e0e0e0;
    align-items: center;
    margin-bottom: 8px;
}

.batch-item span {
    font-size: 14px;
}

/* UID Column */
.uid-column {
    padding: 10px;
    /* height: 600px; */
}

.uid-column h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
}

.uid-item {
    font-size: 14px;
    padding: 5px 0;
    background-color: #F1F1FF;
    height: 500px;
}
.uid-items {
    text-align: left;
    height: 430px;
    overflow-y: auto;
}
.uid-items-each {
    padding: 20px;
    margin-bottom: 8px;
}
/* Assign Button */
.assign-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 40px;
    margin-top: 5px;
}

.assign-button {
    background-color: #1C407B;
    color: #fff;
    text-align: center;
    border: 1px solid #1E3760;
    border-radius: 4px;
    width: 150px;
    padding: 12px;
}
.productionline-dialog-title {
    background-color: #1C407B;
    color: #fff;
    text-align: center;
    border: 1px solid #1E3760;
    border-radius: 4px;
}