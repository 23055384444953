.header-wrapper {
    background-color: #fff !important;
    box-shadow: 0px 6px 8px #A7A7A729 !important;
    position: fixed !important;
    top: 0;
    width: 100% !important;
    z-index: 10;
}

.customerPortal-Header {
    height: 74px;
    background-color: #ffff;
    position: relative;
    border-radius: 0px 0px 24px 24px;
    box-shadow: 0px 6px 12px #00000029;
}
.upload-excel-data{
    display: flex !important;
    gap: 15px;
}

.customerPortal-logo {
    position: absolute;
    height: 41px;
    margin-top: 15px;
    margin-left: 20px;
}

.header-logo {
    height: 38px;
}

.toolbar-wraper {
    justify-content: space-between;
    align-items: center;
    height: 74px;
}

.header-toolbar-wrapper {
    display: flex;
    gap: 25px;
    align-items: center;
}

.profile-toggle-menu {
    gap: 10px;
    display: flex;
    align-items: center;
    padding-right: 35px;
    padding-left: 25px;
    border-left: 1px solid #DCE1EA;
    cursor: pointer;
}

.profile-toggle-menu p {
    margin-bottom: 0;
    font-size: 18px;
    color: #001323;
    font-weight: 700;
}

.profile-toggle-menu .down-icon {
    height: 16px;
    transform: rotate(90deg);
    color: #001323;
    position: absolute;
    right: 0;
    margin-top: 3px;
}

.expand-screen-btn,
.notification-btn {
    cursor: pointer;
    position: relative;
}

.expand-screen-btn img,
.notification-btn img {
    height: 24px;
    width: 24px;
}

.notification-btn {
    padding-right: 8px;
}

.notification-btn span {
    height: 18px;
    width: 18px;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #c80001;
    border-radius: 50%;
    font-size: 10px;
    color: #fff;
}

.date-time-wrapper p {
    color: #001323;
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 500;
}

.profile-item {
    display: flex;
    gap: 16px;
    align-items: center;
}

.profile-item .profile-image {
    height: 60px !important;
    width: 60px !important;
}

.profile-item .profile-detail p.user-name {
    margin: 0;
    font-size: 17px;
    font-weight: 700;
    color: #001323;
}

.profile-item .profile-detail p.user-email {
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    color: #001323;
}

.menu-item {
    color: #001323;
    font-weight: 500;
    padding: 8px 14px !important;
    justify-content: flex-start !important;
    display: flex !important;
}

.menu-item svg {
    fill: #001323;
}
@media (max-width: 767.98px) {

    .profile-name,
    .date-time-wrapper {
        display: none;
    }
}

@media (max-width: 599.98px) {

    .expand-screen-btn img,
    .notification-btn img {
        height: 20px;
        width: 20px;
    }

    .header-logo {
        height: 28px;
    }

    .toolbar-wraper {
        height: 64px;
    }

    .header-toolbar-wrapper {
        gap: 18px;
    }

    .profile-toggle-menu {
        padding-right: 28px;
        padding-left: 16px;
    }
}